import axios from './axios'

import cloudStore from './modules/cloudStore'
import services from './modules/services'
import common from './modules/common'

export default () => ({
  cloudStore: cloudStore(axios),
  services: services(axios),
  common: common(axios)
})
