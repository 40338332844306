export default {
  namespaced: true,
  state: {
    required: 'Заполните это поле.',
    email: 'Некорректный адрес почты.',
    numeric: 'Значение должно быть числовым.',
    minLength (num) {
      if (!num) { return 'Недостаточно символов.' }
      return `Минимальная длинна ${num} символов`
    }
  }
}
