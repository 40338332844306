<template>
  <div class="menu">
    <MenuItem
      v-for="(item, index) of items"
      :key="index"
      :info="item"
    />
  </div>
</template>

<script>
import MenuItem from './MenuItem'

export default {
  name: 'MobileMenu',
  components: {
    MenuItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {}
</style>
