import vuelidate from './modules/vuelidate'
import { createStore } from 'vuex'

export default createStore({
  state: {
    advantages: [
      {
        image: '/images/adv1.svg',
        title: 'Оборудование',
        text: 'Используем только HIGH-END класса оборудование: Cisco, IBM, EMC.'
      },
      {
        image: '/images/adv2.svg',
        title: 'Размещение',
        text: 'В наличии собственные ЦОДы.'
      },
      {
        image: '/images/adv3.svg',
        title: 'Отказоустойчивость',
        text: 'Отказоустойчивый кластер растянутый между площадками и ЦОД минимизирует простой ваших сервисов.'
      },
      {
        image: '/images/adv4.svg',
        title: 'Поддержка 24/7',
        text: 'Техническая поддержка работает круглосуточно без выходных.'
      }
    ],
    partners: [
      '/images/veeam.png',
      '/images/vmware.png',
      '/images/lenovo.png',
      '/images/cisco.png',
      '/images/1c.png'
    ]
  },
  getters: {
    advantages: state => state.advantages,
    partners: state => state.partners
  },
  modules: {
    vuelidate
  }
})
