import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL,
  headers: {
    authorization: '0BUsO6v8PiGJRP9mKA5hecQfcqiULmeHK49d3HTu6W3BrSh'
  }
})

export default axiosInstance
