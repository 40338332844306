<template>
  <div class="default-layout">
    <header class="header">
      <div class="header__left">
        <a href="/" class="header__logo-wrapper">
          <img
            src="/images/logo.svg"
            alt="logo"
            class="header__logo"
          >
        </a>

        <div class="menu">
          <div class="menu__item">
            <BaseLink href="/">
              Главная
            </BaseLink>
          </div>
          <div class="menu__item">
            <BaseDropdown
              text="Условия"
              style="max-width: 250px;"
            >
              <BaseDropdownItemButton href="/sla">
                SLA
              </BaseDropdownItemButton>
            </BaseDropdown>
          </div>
          <div class="menu__item">
            <BaseDropdown
              text="Сервисы и услуги"
              style="width: 250px;"
            >
              <BaseDropdownItemButton
                v-for="(service, index) of services"
                :key="index"
                :href="service.href"
              >
                {{ service.label }}
              </BaseDropdownItemButton>
            </BaseDropdown>
          </div>
        </div>
      </div>
      <div class="header__right">
        <div class="links">
          <BaseLink
            href="/about"
            class="links__item"
          >
            О компании
          </BaseLink>
          <BaseLink
            href="/contract"
            class="links__item"
          >
            Договор оферты
          </BaseLink>
        </div>

        <div class="numbers">
          <div class="numbers__item">
            +7 (495) 269-50-14
          </div>
        </div>

        <BaseButton
          href="#"
          class="header__auth"
        >
          Войти
        </BaseButton>
      </div>
    </header>

    <header class="header-mobile">
      <a href="/" class="header-mobile__logo-wrapper">
        <img src="/images/logo.svg" alt="logo" class="header-mobile___logo">
      </a>
      <div class="header-mobile__right">
        <BaseButton class="header-mobile__auth">
          Войти
        </BaseButton>
        <AppHamburger
          v-model="sidebar"
          class="header-mobile__hamburger"
        />
      </div>
    </header>

    <div class="default-layout__slot-and-footer">
      <div
        class="sidebar"
        :class="{ sidebar_active: sidebar}"
      >
        <MobileMenu
          :items="mobileLinks"
        />
      </div>

      <slot />

      <footer class="footer">
        <div class="container">
          <div class="footer__left">
            <img
              src="/images/logo2.svg"
              alt="logo"
              class="logo"
            >
          </div>
          <div class="footer__right">
            <nav class="nav">
              <a
                v-for="(item, index) of footerLinks"
                :key="index"
                :href="item.href"
                class="nav__item"
              >
                {{ item.label }}
              </a>
            </nav>

            <div class="socials">
              <a
                v-for="(item, index) of socials"
                :key="index"
                :href="item.href"
                target="_blank"
                class="socials__item"
              >
                <img :src="item.image" alt="social" class="image">
              </a>
            </div>

            <p class="text">
              © 2021-2022 Cloud-Bit
            </p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import BaseLink from '@/components/Base/BaseLink'
import BaseButton from '@/components/Base/BaseButton'
import BaseDropdown from '@/components/Base/BaseDropdown'
import BaseDropdownItemButton from '@/components/Base/BaseDropdown/BaseDropdownItem'
import AppHamburger from '@/components/AppHamburger'
import MobileMenu from '@/components/MobileMenu'

const links = Object.freeze({
  home: { href: '/', label: 'Главная' },
  infrastructure: { href: '/infrastructure', label: 'Инфраструктура в облаке' },
  vds: { href: '/vds', label: 'VDS' },
  virtualAtc: { href: '/virtual-atc', label: 'Виртуальный АТС' },
  onec: { href: '/onec', label: '1С в облаке' },
  cloudStore: { href: '/cloud-store', label: 'Облачное хранилище (облачный диск)' },
  backup: { href: '/backup', label: 'Резервное копирование' },
  sla: { href: '/sla', label: 'SLA' },
  about: { href: '/about', label: 'О компании' },
  contract: { href: '/contract', label: 'Договор оферты' }
})

export default {
  name: 'LayoutDefault',
  components: {
    MobileMenu,
    AppHamburger,
    BaseLink,
    BaseButton,
    BaseDropdown,
    BaseDropdownItemButton
  },
  data () {
    return {
      sidebar: false,
      links,
      services: [
        links.infrastructure,
        links.vds,
        links.virtualAtc,
        links.onec,
        links.cloudStore,
        links.backup
      ],
      footerLinks: [
        links.infrastructure,
        links.vds,
        links.virtualAtc,
        links.onec,
        links.cloudStore,
        links.backup
      ],
      socials: [
        { href: 'https://t.me/cloud_bit', image: '/images/facebook.svg' }
      ],
      mobileLinks: [
        links.home,
        {
          label: 'Условия',
          children: [
            links.sla
          ]
        },
        {
          label: 'Сервисы и услуги',
          children: [
            links.infrastructure,
            links.vds,
            links.virtualAtc,
            links.onec,
            links.cloudStore,
            links.backup
          ]
        },
        links.about,
        links.contract
      ]
    }
  }
}
</script>

<style lang="scss">
$secondary-color:                         #046DA7 !default;

.default-layout {
  min-height: 100vh;

  .header {
    max-width: 1920px;
    margin: 0 auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1400px) {
      display: none;
    }

    &__left {
      display: flex;
      align-items: center;
      .menu {
        margin-left: 87px;
        display: flex;
        align-items: center;
        &__item {
          &:not(:last-child) {
            margin-right: 50px;
          }
        }
      }
    }

    &__logo-wrapper {
      width: 68px;
      display: block;
      flex-shrink: 0;
    }

    &__logo {
      display: block;
      width: 100%;
    }

    &__right {
      display: flex;
      align-items: center;

      .links {
        display: flex;
        align-items: center;

        &__item {
          white-space: nowrap;
          margin-right: 50px;
        }
      }

      .numbers {
        margin-right: 60px;
        white-space: nowrap;

        &__item {
          font-size: 14px;
          color: #005485;
          line-height: 1.3;
        }
      }
    }

    &__auth  {
      padding: 0 30px;
    }
  }

  .header-mobile {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ececec;

    @media screen and (min-width: 1401px) {
      display: none;
    }

    &___logo {
      display: block;
      width: 62px;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__hamburger {
      margin-left: 32px;
    }

    &__auth {
      height: 40px;
    }
  }

  &__slot-and-footer {
    position: relative;
  }

  .sidebar {
    position: absolute;
    background: #fff;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    z-index: 1000;
    transition: .3s ease-in;
    padding: 10px 40px;

    &_active {
      left: 0;
    }
  }

  .footer {
    background:
      url('/images/cloud-razm.png') no-repeat left 0,
      url('/images/cloud-razm.png') no-repeat right 0;
    background-color: $secondary-color;
    padding: 103px 0 56px;
    margin-top: 120px;

    @media screen and (max-width: $media-sm) {
      background-size: 0;
      padding: 40px 0;
    }

    .container {
      display: flex;

      @media screen and (max-width: $media-sm) {
        flex-direction: column-reverse;
      }
    }

    &__left {
      padding-top: 15px;

      @media screen and (max-width: $media-sm) {
        padding-top: 0;
        margin-top: 40px;
      }

      .logo {
        display: block;
        width: 145px;
      }
    }

    &__right {
      margin-left: 47px;

      @media screen and (max-width: $media-sm) {
        margin-left: 0;
      }

      .nav {
        display: flex;
        flex-wrap: wrap;

        &__item {
          font-size: 14px;
          line-height: 1.7;
          color: #FFFFFF;
          padding-right: 10px;
          text-decoration: none;
          white-space: nowrap;
          margin-bottom: 10px;

          @media screen and (max-width: $media-sm) {
            display: block;
          }

          &:not(:last-child) {
            border-right: 1px solid #fff;
            margin-right: 10px;

            @media screen and (max-width: $media-sm) {
              border-right: none;
              margin-right: 0;
              margin-bottom: 12px;
            }
          }
        }
      }
      .socials {
        display: grid;
        grid-template-columns: repeat(auto-fill, 40px);
        grid-auto-rows: 40px;
        grid-gap: 20px;
        margin-top: 20px;
      }
      .text {
        color: #fff;
        margin-top: 30px;
      }
    }
  }

  .backup-section {
    .desc {
      font-size: 18px;
      line-height: 1.6;
      margin-top: 40px;
      max-width: 970px;
    }
    .calc {
      &-row {
        display: grid;
        grid-template-columns: 200px 1fr 100px;
        grid-column-gap: 30px;
        margin-top: 40px;

        @media screen and (max-width: $media-md) {
          grid-column-gap: 10px;
        }
        @media screen and (max-width: $media-sm) {
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 30px;
        }

        &__title {
          @media screen and (max-width: $media-sm) {
            order: 1;
          }
        }
        &__slider {
          @media screen and (max-width: $media-sm) {
            order: 3;
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }

        &__val {
          color: $primary-color;
          font-weight: 600;
          text-align: right;
          @media screen and (max-width: $media-sm) {
            order: 2;
          }
        }
      }
    }
  }
}
</style>
