export default axios => ({
  list () {
    return axios.get('/service/cloudStorage')
  },
  sendRequest ({ applicationId, service, price }) {
    return axios.post('/application/cloudStorage', {
      applicationId, service, price
    })
  }
})
