import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    meta: { layout: 'DefaultLayout', title: 'Главная' },
    component: () => import('../views/home')
  },
  {
    path: '/virtual-atc',
    meta: { layout: 'DefaultLayout', title: 'Виртуальный АТС' },
    component: () => import('../views/virtual-atc')
  },
  {
    path: '/cloud-store',
    meta: { layout: 'DefaultLayout', title: 'Облачное хранилище' },
    component: () => import('../views/cloud-store')
  },
  {
    path: '/vds',
    meta: { layout: 'DefaultLayout', title: 'VDS' },
    component: () => import('../views/vds')
  },
  {
    path: '/backup',
    meta: { layout: 'DefaultLayout', title: 'Резервное копирование' },
    component: () => import('../views/backup')
  },
  {
    path: '/onec',
    meta: { layout: 'DefaultLayout', title: '1C в облаке' },
    component: () => import('../views/one-c')
  },
  {
    path: '/about',
    meta: { layout: 'DefaultLayout', title: 'О компании' },
    component: () => import('../views/about')
  },
  {
    path: '/contract',
    meta: { layout: 'DefaultLayout', title: 'Договор оферты' },
    component: () => import('../views/contract')
  },
  {
    path: '/infrastructure',
    meta: { layout: 'DefaultLayout', title: 'Инфраструктура в облаке' },
    component: () => import('../views/infrastructure')
  },
  {
    path: '/sla',
    meta: { layout: 'DefaultLayout', title: 'SLA' },
    component: () => import('../views/sla')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ?? 'Cloud-bit'
  next()
})

export default router
