<template>
  <div class="hamburger-menu">
    <input
      v-model="isOpen"
      id="menu__toggle"
      type="checkbox"
    />
    <label class="menu__btn" for="menu__toggle">
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppHamburger',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    'update:modelValue': null
  },
  data () {
    return {
      isOpen: this.modelValue
    }
  },
  watch: {
    modelValue (val) {
      this.isOpen = val
    },
    isOpen (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>

<style lang="scss" scoped>
#menu__toggle {
  display: none;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: relative;

  width: 26px;
  height: 26px;

  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
</style>
