<template>
  <div
    class="b-dropdown"
    v-click-outside="hide"
  >
    <template v-if="$slots.button">
      <slot name="button" />
    </template>

    <template v-else>
      <div
        class="text-preview"
        @click="toggle"
      >
        <div class="text-preview__content">
          <BaseLink
            :hasHover="false"
          >{{ text }}</BaseLink>
        </div>
        <div class="text-preview__arrow-wrapper">
          <BaseIcon
            icon="arrow-down"
            class="text-preview__arrow"
            :class="{ 'text-preview__arrow_active': open }"
          />
        </div>
      </div>
    </template>

    <div
      v-bind="$attrs"
      class="options"
      :class="{ options_active: open }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'

import BaseIcon from '@/components/Base/BaseIcon'
import BaseLink from '@/components/Base/BaseLink'

export default {
  name: 'BaseDropdown',
  components: {
    BaseLink,
    BaseIcon
  },
  props: {
    text: String
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    show () {
      this.open = true
    },
    hide () {
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.b-dropdown {
  position: relative;
  z-index: 2;

  .text-preview {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &__arrow-wrapper {
      width: 10px;
      margin-left: 12px;
      display: flex;
      align-items: center;
    }
    &__arrow {
      transition: .2s;
      &_active {
        transform: rotate(180deg);
      }
    }
  }
  .options {
    min-width: 200px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: absolute;
    top: 100%;
    font-size: 1rem;
    border: 1px solid rgba(0,0,0,.15);
    padding: 10px 10px;
    transition: all .4s ease-in-out;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);
    &_active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateY(4px);
    }
  }
}
</style>
