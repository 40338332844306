<template>
  <BaseLink
    :href="href"
    class="dropdown-item"
  >
    <slot />
  </BaseLink>
</template>

<script>
import BaseLink from '@/components/Base/BaseLink'

export default {
  name: 'BaseDropdownItem',
  components: {
    BaseLink
  },
  props: {
    href: String
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  padding: 10px;
  width: 100%;
  display: block;
}
</style>
