<template>
  <component
    :is="tagName"
    class="k-button"
    :class="classes"
    :href="href"
    :target="target"
    :to="to"
    :type="computedType"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    disabled: Boolean,
    block: Boolean,
    pill: Boolean,
    squared: Boolean,
    href: String,
    target: String,
    to: String,
    type: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'default',
      validator: val => ['default', 'secondary', 'light'].includes(val)
    },
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    }
  },
  computed: {
    classes () {
      return {
        /* THEMES */
        'k-button-secondary': this.variant === 'secondary',
        'k-button-default': this.variant === 'default',
        'k-button-light': this.variant === 'light',
        /* SIZES */
        'k-button-sm': this.size === 'sm',
        'k-button-md': this.size === 'md',
        'k-button-lg': this.size === 'lg',
        'k-button_disabled': this.disabled,
        'k-button_block': this.block,
        'k-button_pill': this.pill,
        'k-button_squared': this.squared
      }
    },
    tagName () {
      if (this.to) {
        return 'RouterLink'
      } else if (this.href) {
        return 'a'
      }
      return 'button'
    },
    computedType () {
      if (this.tagName === 'button' && !this.type) {
        return 'button'
      }
      return this.type
    }
  }
}
</script>

<style lang="scss" scoped>
$input-size-sm:                            40px !default;
$input-size-md:                            50px !default;
$input-size-lg:                            60px !default;
$primary-color:                            #3EBBEA !default;
$disabled-color:                           #ABABAB !default;

.k-button {
  margin: 0;
  font-family: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: #212529;
  text-align: center;
  user-select: none;
  background-color: transparent;
  border: 1.5px solid transparent;
  font-size: 16px;
  border-radius: 4px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;

  /* SIZES */
  &-sm {
    height: $input-size-sm;
    padding: 0 12px;
  }
  &-md {
    height: $input-size-md;
    padding: 0 14px;
  }
  &-lg {
    height: $input-size-lg;
    padding: 0 20px;
  }
  /* SIZES */

  /* STYLES */
  &-default {
    color: $primary-color;
    background-color: transparent;
    border-color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: #fff;
    }

    &:active {
      background-color: #046DA7;
    }
  }
  &-secondary {
    $color: #005485;
    color: #fff;
    background-color: $color;
    border-color: $color;
    &:hover {
      $color: #005A8F;
      background-color: $color;
      border-color: $color;
    }
    &:active {
      background-color: #004770;
    }
  }
  &-light {
    $color: #EEF0F1;
    color: #000;
    background-color: $color;
    border-color: $color;
    &:hover {
      $color: #DFE0E2;
      background-color: $color;
      border-color: $color;
    }
    &:active {
      background-color: #DADBDD;
    }
  }
  /* STYLES */

  &_block {
    display: block;
    width: 100%;
  }

  &_pill {
    border-radius: 1000px;
  }

  &_squared {
    border-radius: 0;
  }

  &_disabled {
    color: #fff !important;
    background-color: $disabled-color !important;
    border-color: $disabled-color !important;
    pointer-events: none !important;
  }
}
</style>
