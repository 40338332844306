<template>
  <div class="layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutEmpty'
}
</script>

<style lang="scss" scoped>
.layout {
}
</style>
