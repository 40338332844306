export default axios => ({
  fetchVDS () {
    return axios.get('/service/vds')
  },
  sendVDS (body) {
    return axios.post('/application/vds', body)
  },
  fetchBackup () {
    return axios.get('/service/backup')
  },
  sendBackup (body) {
    return axios.post('/application/backup', body)
  },
  fetchOnec () {
    return axios.get('/service/onec')
  },
  sendOnec (body) {
    return axios.post('/application/onec', body)
  },
  fetchInfrastructure () {
    return axios.get('/service/infrastructure')
  },
  sendInfrastructure (body) {
    return axios.post('/application/infrastructure', body)
  },
  fetchATC () {
    return axios.get('/service/atc')
  },
  sendATC (body) {
    return axios.post('/application/atc', body)
  },
  additional (url) {
    return axios.get(url)
  },
  operatingSystems () {
    return axios.get('/service/vds/os')
  },
  licenses (osId) {
    return axios.get('/service/vds/os', {
      params: { osId }
    })
  },
  network () {
    return axios.get('/service/infrastructure')
  },
  infrastructureLicenses () {
    return axios.get('/service/infrastructure/license')
  }
})
