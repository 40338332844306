<template>
  <a
    v-if="!info.children"
    :href="info.href"
    class="item item-without-arrow"
  >
    {{ info.label }}
  </a>

  <div
    v-else
    class="item item-with-arrow"
  >
    <div
      class="preview"
      @click="open = !open"
    >
      {{ info.label }}

      <BaseIcon
        icon="arrow-down"
        style="width: 12px;"
        class="preview__icon"
        :class="{ preview__icon_active: open }"
      />
    </div>

    <div
      v-if="open"
      class="list"
    >
      <a
        v-for="(i, index) of info.children"
        :key="index"
        :href="i.href"
        class="list__item"
      >
        {{ i.label }}
      </a>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
  name: 'MenuItem',
  components: { BaseIcon },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      open: false
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 12px 12px 12px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: .2s;
  user-select: none;

  &-without-arrow {}

  &-with-arrow {
    .preview {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__icon {
        transition: .2s;

        &_active {
          transform: rotate(180deg);
        }
      }
    }

    .list {
      padding-left: 22px;

      &__item {
        display: block;
        color: inherit;
        text-decoration: none;
        margin-top: 18px;
      }
    }
  }
}
</style>
