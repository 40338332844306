<template>
  <component
    :is="tagName"
    :href="href"
    class="link"
    :class="{ link_static: !hasHover }"
  >
    <span>
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    href: String,
    hasHover: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    tagName () {
      return this.href ? 'a' : 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
$primary-color:                           #3EBBEA !default;

.link {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 500;
  color: #333333;
  text-align: inherit;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  * {
    transition: .2s;
  }
  &:not(.link_static):hover {
    span {
      color: $primary-color;
      &:after {
        background: $primary-color;
        width: 100%;
        transition: .3s;
      }
    }
  }
  span {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      width: 0;
      height: 2px;
      border-radius: 1000px;
      background: #000;
      left: 0;
    }
  }
}
</style>
